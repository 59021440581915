import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngMetaversePage = loadable(() => import("../components/MetaversePage/english.js"));
const ArabicMetaversePage = loadable(() => import("../components/MetaversePage/arabic.js"));
const ChineseMetaversePage = loadable(() => import("../components/MetaversePage/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-5.webp",
      "name": "The Leading Metaverse Development Services",
      "description": "Softtik, as a leading metaverse development services, helps you design and launch customized virtual spaces for interactive social engagement.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "139"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is Metaverse Development?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Metaverse development involves building shared virtual spaces that provide an interactive and immersive experience. These virtual spaces seem close to reality due to the usage of AR & VR.</p>"
        }
      }, {
        "@type": "Question",
        "name": "What is the Metaverse used for?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Just as in the real world, users utilize Metaverse to trade digital goods such as land and buildings and explore the universe using their avatars. The <b>Metaverse</b> is a highly interactive, three-dimensional virtual world.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How do you make money on Metaverse?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Metaverse offers unlimited opportunities to make money. You can earn money by building a P2E game, arranging a concert in your <b>virtual space</b>, purchasing and selling lands, or designing VR accessories.</p>"
        }
      }
      ]
    }
  ]
}

export class MetaversePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English",
    }
  };

  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    // else if (region == 'sa') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;
    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/metaverse-development-services/"
            title="Metaverse Development Services - Softtik Technologies"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-5.webp"
            description="Softtik, as a leading metaverse development services, helps you design and launch customized virtual spaces for interactive social engagement."
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicMetaversePage />
                  : lang == "China"
                    ? <ChineseMetaversePage />
                    : <EngMetaversePage />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default MetaversePage;